import { JsonRepository } from '../../JsonRepository';
import { FaqData } from '../../../../data/faq-data';
import { FaqCategory } from '../../../../domain/models/Customer/faq';

export class FaqRepository extends JsonRepository {
  protected data: FaqCategory[] = FaqData;

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  getFaqQuestions = async (country: string) => {
    return await this.list({ country });
  };
}
