import React from 'react';

export interface AuthenticationContext {
  isLogged: boolean;
  setIsLogged(logged: boolean): void;
}

export const defaultContext: AuthenticationContext = {
  isLogged: false,
  setIsLogged: (logged: boolean) => null,
};

const AuthContext = React.createContext<AuthenticationContext>(defaultContext);

export default AuthContext;
