import React, { ReactElement } from 'react';
import { Button, Modal, Form, InputGroup, Spinner } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';

import './index.css';
import { AuthRepository } from 'infraestructure/repositories';
import Routes from 'presentation/routes';
import AuthContext, { AuthenticationContext } from 'presentation/Context/Auth';
import { EyeIcon, LockIcon, EyeSlashIcon, UserIcon } from 'react-line-awesome';

export interface LoginProps {
  show: boolean;
  handleClose(): void;
}

interface LoginCredentials {
  username: string;
  password: string;
}

export const Login = (props: LoginProps): ReactElement => {
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [isLogging, setIsLoggin] = React.useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const history = useHistory();

  const context = React.useContext<AuthenticationContext>(AuthContext);

  const repository = new AuthRepository();

  const handleLogin: SubmitHandler<any> = async (data: LoginCredentials) => {
    setIsLoggin(true);

    const token = await repository.login(data.username, data.password);

    setIsLoggin(false);
    if (token) {
      repository.storeToken(token);
      context.setIsLogged(true);
      props.handleClose();
      setTimeout(() => history.push(Routes.dashboard), 220);
    }
  };

  const onPassRecovery = () => {
    props.handleClose();
    history.push(Routes.passwordRecovery);
  };

  return (
    <>
      <Modal
        className="bg-login"
        show={props.show}
        onHide={props.handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton className="login-header">
          <div className="px-4">
            <Modal.Title className="title-bryant">Inicio de sesión</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="px-4">
            <Form onSubmit={handleSubmit(handleLogin)}>
              <Form.Group>
                <Form.Label className="login-input-label">Usuario</Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      id="user-icon"
                      className="bg-light border-0"
                    >
                      <UserIcon className="text-danger" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    {...register('username', { required: true })}
                    isInvalid={!!errors?.username}
                    className="bg-light border-0"
                  />
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <Form.Label className="login-input-label">
                  Contraseña
                </Form.Label>
                <InputGroup>
                  <InputGroup.Prepend>
                    <InputGroup.Text
                      id="user-icon"
                      className="bg-light border-0"
                    >
                      <LockIcon className="text-danger" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    {...register('password', { required: true })}
                    type={showPassword ? 'text' : 'password'}
                    className="input-password bg-light border-0"
                    isInvalid={!!errors?.password}
                  />
                  <InputGroup.Append
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <InputGroup.Text className="login-eye-btn bg-light border-0 text-danger">
                      {showPassword ? <EyeIcon /> : <EyeSlashIcon />}
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Form.Group>
              <div className="pt-1 pb-3">
                <Button
                  variant="link"
                  onClick={onPassRecovery}
                  className="text-primary"
                >
                  Olvide mi contraseña
                </Button>
              </div>
              <Button
                variant="danger"
                className="btn-block btn-lg title-bryant"
                disabled={isLogging}
                type="submit"
              >
                {isLogging ? (
                  <Spinner
                    as="span"
                    size="sm"
                    animation="border"
                    className="mr-4"
                  />
                ) : (
                  ' '
                )}
                Iniciar Sesion
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

Login.defaultProps = {
  show: false,
};

export default Login;
