import { BaseHttpRepository } from 'infraestructure/repositories';
import { PromotionResponse } from 'domain/PayloadResponse/Promotion';

export class PromotionRepository extends BaseHttpRepository {
  endpoint = 'customers';

  protected getService(): string {
    return process.env.REACT_APP_CUSTOMER_SERVICE || '';
  }

  async getPromotions(country: string | null): Promise<PromotionResponse> {
    const response = await this.client.get(this.buildUrl('promotion'), {
      country: country,
    });

    const payload = response.data as PromotionResponse;
    return payload;
  }

  async getAllPromotions(
    country: string | null,
    name: string | null,
    date: string | number | boolean | null
  ): Promise<PromotionResponse> {
    const response = await this.client.get(this.buildUrl('promotion/search'), {
      country: country,
      name: name,
      date: date,
    });
    const payload = response.data as PromotionResponse;
    return payload;
  }
}
