import React, { CSSProperties, ReactElement } from 'react';
import './index.css';
import { Card } from 'react-bootstrap';

import { SimpleTitle } from 'presentation/components';

export interface SectionBannerProps {
  imageUrl: string;
  title: string;
  style?: CSSProperties;
}

export const SectionBanner = (props: SectionBannerProps): ReactElement => {
  return (
    <Card className="bg-dark text-white border-0">
      <Card.Img src={props.imageUrl} style={props.style} alt="Image..." />
      <Card.ImgOverlay style={{ height: '100%', padding: 0 }}>
        <div className="overlayImage"></div>
        <div className=" px-5 title-banner">
          <SimpleTitle title={props.title} />
        </div>
      </Card.ImgOverlay>
    </Card>
  );
};

// SectionBanner.defaultProps = {
//   style: { maxHeight: '35rem' },
// };
