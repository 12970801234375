import { AxiosError } from 'axios';
import { AppStore } from '../../store';
import { logout } from '../../store/actions/auth';
import { showAlert } from '../../store/actions/ui';

const mapErroToList = (error: any): string[] => {
  const errors: string[] = [];
  Object.keys(error).forEach((key) => {
    errors.push(`${key}: ${error[key]}`);
  });

  return errors;
};

export const ErrorHandler = (error: AxiosError): AxiosError | null => {
  const response = error.response;
  switch (response?.status) {
    case 401:
      AppStore.instance.dispatch(logout());
      AppStore.instance.dispatch(showAlert('Tu sesion ha expirado', 'info'));
      break;

    case 400:
      mapErroToList(response.data).forEach((err: string) => {
        AppStore.instance.dispatch(showAlert(err, 'warning'));
      });
      break;

    case 404:
      AppStore.instance.dispatch(
        showAlert('El servicio no esta disponible', 'warning')
      );
      break;

    case 500:
    case 501:
    case 502:
    case 503:
      AppStore.instance.dispatch(
        showAlert('Ha ocurrido un error en el servicio', 'danger')
      );
  }

  if (!response) {
    AppStore.instance.dispatch(
      showAlert('No se ha podido establecer conexión con el servicio', 'danger')
    );
    //throw error;
  }
  return null;
};

export default ErrorHandler;
