import { BaseHttpRepository } from 'infraestructure/repositories';
import { CompanyResponse } from 'domain/PayloadResponse/Company';
import { SellingPointResponse } from 'domain/PayloadResponse/SellingPoint';

export class CompanyRepository extends BaseHttpRepository {
  endpoint = 'admin';

  // protected getService(): string {
  //   return process.env.REACT_APP_ADMIN_SERVICE || '';
  // }

  //Obtener toda la lista de compañias que son comercios afiliados
  async getCompanies(
    page: number,
    searchCompany: string,
    country: string | null
  ): Promise<CompanyResponse> {
    const response = await this.client.get(this.buildUrl('company/search'), {
      query: searchCompany,
      country: country,
      page: page,
    });

    const payload = response.data as CompanyResponse;
    return payload;
  }

  //Obtener una compañia en especifico
  async getSpecificCompany(companyId: number): Promise<CompanyResponse> {
    const response = await this.client.get(this.buildUrl('company'), {
      isCA: 1,
      companyId: companyId,
    });

    const payload = response.data as CompanyResponse;
    return payload;
  }

  //Obtener lista de sucursales de una compañia
  async getSellingPoints(
    companyId: number,
    country: string | null
  ): Promise<SellingPointResponse> {
    const response = await this.client.get(
      this.buildUrl('sellingpoint/withtime'),
      {
        companyId: companyId,
        country: country,
      }
    );

    const payload = response.data as SellingPointResponse;
    return payload;
  }

  async affiliate(data: any): Promise<any> {
    return await this.client.post(this.buildUrl('company/affiliate'), data);
  }
}
