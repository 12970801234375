import { HttpRepository } from 'infraestructure/repositories/';

export class CreditRepository extends HttpRepository {
  endpoint = 'credit';

  // protected getService(): string {
  //   return process.env.REACT_APP_CREDIT_SERVICE || '';
  // }

  simulate = async (type: number, amount: number): Promise<any> => {
    return await this.client.get(`${this.endpoint}/simulator`, {
      type,
      amount,
    });
  };

  requestCredit = async (data: any): Promise<any> => {
    return await this.client.post(`${this.endpoint}/creditrequest`, data);
  };
}
