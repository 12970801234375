import React, { ReactElement } from 'react';
import { Container, Row } from 'react-bootstrap';
import './index.css';

export interface StickyProps {
  children: JSX.Element | JSX.Element[];
}

export const StickyHeader = (props: StickyProps): ReactElement => {
  const { children } = props;
  return <div className="sticky-header ">{children}</div>;
};

export const StickyBody = (props: StickyProps): ReactElement => {
  const { children } = props;
  return <div className="sticky-content">{children}</div>;
};

export const Sticky = (props: StickyProps): ReactElement => {
  const { children } = props;
  return (
    <Container fluid className="special-container">
      <Row>{children}</Row>
    </Container>
  );
};

export default Sticky;
