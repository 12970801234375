import { FaqCategory } from '../domain/models/Customer/faq';
import Routes from '../presentation/routes';
import startImg from '../assets/images/tools/contract.svg';
import requirementimg from '../assets/images/iconos/lista-de-quehaceres.svg';
import credexImg from '../assets/images/iconos/credex.svg';
import contactUs from '../assets/images/logos/contact-us.svg';
import gatantiaTotalLogo from '../assets/images/logos/gatantia-total-lineas.svg';
import inversionLogo from '../assets/images/logos/inversion.svg';
import remesasLogo from '../assets/images/logos/red-envelope.png';
import { Country } from '../domain/constances/country';

const site = process.env.REACT_APP_SITE_TITLE || '';
const currency = process.env.REACT_APP_LOCAL_CURRENCY || '';
const phone = '1-800-0707';
const phoneCustomerService = `<a href="phone:${
  process.env.REACT_APP_CUSTOMER_SERVICE_PHONE || ''
}" className="phone-faqs">${
  process.env.REACT_APP_CUSTOMER_SERVICE_PHONE || ''
}</a>`;
const linkCalculator = `<a href=${Routes.creditCalculator} target="_blank">
  simula tu crédito
  </a>`;
const linkClaims = `<a href=${Routes.claimProcess} target="_blank">
  proceso de atención a reclamos
  </a>`;

const linkCalculatorInversion = `<a href="${Routes.inversiones}#inversion-calculator" target="_blank">
  Calculadora de Intereses
</a>`;

const liknPhone = `<a href="tel:${phone}">${phone}</a>`;

export const FaqData: FaqCategory[] = [
  {
    title: `Apertura de línea ${site}`,
    icon: `${startImg}`,
    eventKey: 'tab1',
    country: Country.NI,
    questions: [
      {
        questionName:
          '¿En cuántas cuotas puedo llevar artículos con una línea de crédito?',
        answer: `Con ${site}, puede llevarlo en cuotas que van desde 3 hasta 36 meses`,
        eventKey: 'q11',
      },
      {
        questionName: '¿Puedo dar una prima para que sea mas baja la cuota?',
        answer: `Perfectamente puede dar una prima, una vez aprobada su cuenta con ${site} de acuerdo al monto de esta y según su elección, se calcula una cuota que le favorezca.`,
        eventKey: 'q12',
      },
      {
        questionName: '¿Qué cargos se cobran en la cuota?',
        answer: 'Se cobran el saldo de capital y el interés corriente.',
        eventKey: 'q13',
      },
      {
        questionName: '¿Y si yo cancelo antes qué pasa?',
        answer: `Si cancela antes no hay cargos por cancelación anticipada, el interés es sobre saldo a la fecha y su cuenta ${site} queda con saldo disponible para cualquier otra compra que desee realizar.`,
        eventKey: 'q14',
      },
    ],
  },
  {
    title: 'Requisitos',
    icon: `${requirementimg}`,
    eventKey: 'tab2',
    country: Country.NI,
    questions: [
      {
        questionName: '¿Cuáles son los requisitos básicos?',
        answer: `Ingresos mensuales mayores de $ 275 a $ 300.
                   Edad entre 21 y 68 años. 
                   6 meses de trabajar en la misma empresa o, para que quien tenga negocio propio, al menos 12 meses de actividad.`,
        eventKey: 'q21',
      },
      {
        questionName: '¿Que documentos se solicitan?',
        answer:
          'Para asalariados únicamente cédula y colilla del INSS, carta salarial o cheque fiscal.',
        eventKey: 'q22',
      },
      {
        questionName: 'Si trabajo de forma independiente, ¿puedo aplicar?',
        answer:
          'Sí, siempre y cuando presente una constancia salarial o recibo de retención por servicios profesionales.',
        eventKey: 'q23',
      },
    ],
  },
  {
    title: `${site} personal`,
    icon: `${credexImg}`,
    eventKey: 'tab3',
    country: Country.NI,
    questions: [
      {
        questionName: `¿Tiene que ser en ${currency} la transferencia/cheque?`,
        answer: `Puede ser en dólares o ${currency} pero la obligación adquirida por el cliente está en dólares. Sin embargo, los costos de conversión monetaria (que realiza el banco) corren por cuenta del cliente.`,
        eventKey: 'q31',
      },
      {
        questionName: '¿En cuanto tiempo recibo mi dinero?',
        answer:
          'En un lapso máximo de 90 minutos el dinero estará en su cuenta. Si solicita un cheque, varía según el lugar donde se realice la entrega del cheque.',
        eventKey: 'q32',
      },
      {
        questionName: '¿Puedo pagar antes?',
        answer:
          'Sin ningún problema y además no se generará penalización por pagos anticipados.',
        eventKey: 'q33',
      },
      {
        questionName: '¿Se cobra comición por desembolso?',
        answer:
          'Sí, es del 5% sobre el monto financiado y se divide entre las cuotas y no genera algún tipo de interes.',
        eventKey: 'q34',
      },
      {
        questionName: '¿Puedo desembolsar todo mi límite de crédito?',
        answer:
          'Sí, esto varía según la cuota máxima, el nivel de endeudamiento y otros factores que se consideran además de las condiciones financieras requeridas por el cliente.',
        eventKey: 'q35',
      },
      {
        questionName: '¿Que pasa si me roban el cheque?',
        answer:
          'Deberá contactarnos inmediatamente para emitir una orden de no pago.',
        eventKey: 'q36',
      },
      {
        questionName:
          '¿Puedo pedir una transferencia/cheque a nombre de otra persona?',
        answer:
          'Por el momento el beneficiario debe ser el titular de la línea de crédito.',
        eventKey: 'q37',
      },
      {
        questionName: '¿Las tasas de interés cuáles son?',
        answer: `Usted podrá encontrar las tasas de interés en la opción de ${linkCalculator}, la cual irá acorde al producto o plan financiero seleccionado.`,
        eventKey: 'q38',
      },
      {
        questionName: '¿Dónde puedo retirar el cheque?',
        answer:
          'Nosotros hacemos entrega del cheque en la dirección acordada con el cliente.',
        eventKey: 'q39',
      },
      {
        questionName: '¿Qué pasa si me equivoque de cuenta bancaria?',
        answer:
          'Existen puntos de control de seguridad, al momento de la entrevista se le envía un SMS esperando su confirmación y nuestro personal hace constar que la cuenta corresponde.',
        eventKey: 'q40',
      },
    ],
  },
  {
    title: 'Atención reclamos',
    icon: `${contactUs}`,
    eventKey: 'tab4',
    country: Country.NI,
    questions: [
      {
        questionName: '¿Dónde puedo acudir para realizar un reclamo?',
        answer: `Puede llamar a nuestra línea de atención al cliente ${phoneCustomerService} o dirigirse a cualquier centro de atención. Los asesores podrán dar atención a su reclamo.`,
        eventKey: 'q41',
      },
      {
        questionName:
          '¿Cuánto es el tiempo de respuesta luego de realizar el reclamo?',
        answer: `${site}, deberá dar respuesta a su reclamo en 7 días hábiles, desde la fecha de la recepción del mismo. Si la complejidad del caso lo amerita podrá disponer de un plazo adicional de 5 días adicionales, el cual le será notificado.`,
        eventKey: 'q42',
      },
      {
        questionName: '¿Cuál es el procedimiento para realizar un reclamo?',
        answer: `El cliente debe presentar su reclamo por los medios disponibles de credex, donde deberá exponer el motivo de su reclamo, así como la información o soportes que sea necesaria para la atención del mismo. Para mas informacion puede ver ${linkClaims}`,
        eventKey: 'q43',
      },
      {
        questionName:
          '¿Dónde puedo obtener información del estado de mi reclamo?',
        answer: `El cliente podrá obtener información del estado o avances de su reclamo llamando a la línea de atención al cliente ${phoneCustomerService}.`,
        eventKey: 'q44',
      },
      {
        questionName:
          'Si no estoy de acuerdo con la respuesta a mi reclamo ¿Dónde puedo dirigirme?',
        answer:
          'El cliente puede recurrir al Presidente Ejecutivo de la CONAMI, luego de los 30 días calendarios siguientes, a partir del plazo para emitir respuesta. A la dirección: Managua, Nicaragua. Paseo Tiscapa, Busto José Martí, 1c. Este, 1 1/2 c. al Norte. Tel: 2250-9797.',
        eventKey: 'q45',
      },
    ],
  },
  {
    title: 'Garantía Total',
    icon: `${gatantiaTotalLogo}`,
    eventKey: 'tab5',
    country: Country.NI,
    questions: [
      {
        questionName: '¿Qué es Garantía Total?',
        answer:
          'Es un servicio de cobertura de 12, 24 y 36 meses adicional a la garantía ofrecida por el comercio, que cubre reparaciones o restitución del producto adquirido.',
        eventKey: 'q51',
      },
      {
        questionName: '¿Qué productos aplican a Garantía Total?',
        answer:
          'Productos identificados con la etiqueta de “Aplica a Garantía Total”.',
        eventKey: 'q52',
      },
      {
        questionName: '¿Cómo puedo adquirir una Garantía Total?',
        answer:
          'Por medio de cualquiera de los asesores de tienda o en caja al facturar su producto.',
        eventKey: 'q53',
      },
      {
        questionName:
          '¿Cuáles son los requisitos para adquirir una Garantía Total?',
        answer: 'Solo debe presentar su documento de identidad en caja.',
        eventKey: 'q54',
      },
      {
        questionName: '¿Dónde puedo leer los términos y condiciones?',
        answer:
          'En el certificado que se emite al facturar la Garantía Total y en el contrato de servicios no financieros que debe firmar para usar servicio.',
        eventKey: 'q55',
      },
      {
        questionName: '¿Cómo puedo pagar la Garantía Total?',
        answer: 'En efectivo, con tarjeta de crédito y línea credex.',
        eventKey: 'q56',
      },
      {
        questionName: '¿Cómo puedo reportar un incidente?',
        answer: 'Contactando al 2264-7484.',
        eventKey: 'q57',
      },
      {
        questionName: '¿Qué tengo que hacer para usar mi Garantía Total?',
        answer:
          'Llamar al 2264-7484 y reportar un mal funcionamiento del articulo protegido por Garantía Total.',
        eventKey: 'q58',
      },
      {
        questionName: '¿Cuándo comienza la vigencia de la Garantía Total?',
        answer:
          'Una vez finalizada la garantía ofrecida por el comercio en la factura de compra.',
        eventKey: 'q59',
      },
      {
        questionName: '¿Cuándo se da por terminada la Garantía Total?',
        answer:
          'Una vez cumplida la vigencia o cualquiera de las clausulas de terminación del servicio que aparece en el certificado.',
        eventKey: 'q511',
      },
      {
        questionName:
          '¿Puedo aumentar el plazo de mi Garantía Total gratis a 24 o 36 meses?',
        answer:
          'Si, si puede, por medio de cualquiera de los asesores de tienda o en caja.',
        eventKey: 'q512',
      },
      {
        questionName:
          'Si hago uso de la garantía ofrecida por el comercio, ¿Puedo hacer uso de la Garantía Total?',
        answer: 'Si, si puede hacer uso.',
        eventKey: 'q513',
      },
      {
        questionName:
          '¿Puedo adquirir una Garantía Total de un producto que ya compré?',
        answer:
          'Si, siempre y cuando la garantía del comercio aun esté vigente.',
        eventKey: 'q514',
      },
      {
        questionName:
          'Si el producto tiene partes que con garantía adicional de fábrica, ¿Puedo utilizar la Garantía Total luego de su cobertura de fabrica?',
        answer:
          'Si, siempre y cuando el certificado de Garantía Total este vigente.',
        eventKey: 'q515',
      },
      {
        questionName: '¿La restitución del producto genera algún cargo?',
        answer: 'No, no genera ningún cargo.',
        eventKey: 'q516',
      },
      {
        questionName:
          '¿Cuándo se restituye un producto, puedo adquirir una nueva Garantía Total?',
        answer: 'Si, para el nuevo producto adquirido.',
        eventKey: 'q517',
      },
      {
        questionName: '¿Cuándo puedo solicitar la restitución de un producto?',
        answer:
          'Durante la vigencia de la Garantía Total se intentará reparar el bien si tiene reparación y no ha sido reparado más de 2 veces, de lo contrario se restituirá.',
        eventKey: 'q518',
      },
      {
        questionName:
          'Si me restituyen un producto durante la garantía ofrecida por el comercio, ¿Puedo hacer uso de la Garantía Total?',
        answer:
          'Si, siempre y cuando el certificado de Garantía Total este vigente.',
        eventKey: 'q519',
      },
      {
        questionName: '¿Cuándo no puedo hacer uso de mi Garantía Total?',
        answer:
          'Cuando un diagnostico técnico determine que el producto cumple con alguna exclusión del contrato de servicios no financieros.',
        eventKey: 'q520',
      },
      {
        questionName: 'Si estoy en mora ¿Puedo usar la Garantía Total?',
        answer: 'No, no puede hacer uso de la Garantía Total.',
        eventKey: 'q521',
      },
      {
        questionName: '¿La restitución del bien se hará en el mismo comercio?',
        answer: 'Si, si se hará por el mismo comercio.',
        eventKey: 'q522',
      },
      {
        questionName: '¿Puedo renovar una Garantía Total?',
        answer: 'No, es posible mejorarla, pero no renovarla.',
        eventKey: 'q523',
      },
      {
        questionName: '¿Puedo anular una Garantía Total que compré en cuotas?',
        answer: 'No, no es posible.',
        eventKey: 'q524',
      },
      {
        questionName: '¿Qué pasa si pierdo mi certificado de Garantía Total?',
        answer:
          'Puede solicitar reimpresión en el comercio o llamar al 2264-7484 para solicitar se la envíen por correo electrónico.',
        eventKey: 'q525',
      },
      {
        questionName:
          '¿Garantía Total incluye préstamo de equipo durante el periodo de reparación?',
        answer: 'No, no lo incluye.',
        eventKey: 'q526',
      },
      {
        questionName:
          '¿Cuáles son las zonas geográficas donde tengo cobertura de Garantía Total?',
        answer:
          'Managua, Masaya, Carazo, Granada, Boaco, León, Rivas, Matagalpa, Chinandega, Juigalpa y Jinotega.',
        eventKey: 'q527',
      },
      {
        questionName:
          '¿Qué pasa si el producto está ubicado en un lugar sin cobertura?',
        answer:
          'El cliente puede llevar el producto al taller certificado que FID le indique.',
        eventKey: 'q528',
      },
      {
        questionName:
          '¿Puedo llevar el producto personalmente al taller autorizado por FID?',
        answer: 'Si, si puede.',
        eventKey: 'q529',
      },
    ],
  },
  {
    title: 'Inversiones',
    icon: `${inversionLogo}`,
    eventKey: 'tab6',
    country: Country.NI,
    questions: [
      {
        questionName: '¿Qué es FID?',
        answer: `Somos una institución financiera regulada por CONAMI, nuestra razon social es Fondo de Inversiones para el Desarrollo`,
        eventKey: 'q61',
      },
      {
        questionName: '¿Qué es credex?',
        answer: `En credex ofrecemos soluciones financieras innovadoras, sencillas y responsables que promueven el crecimiento sostenible de nuestros clientes socios y colaboradores en los mercados donde operamos.`,
        eventKey: 'q62',
      },
      {
        questionName: '¿Cómo puedo invertir con credex?',
        answer: `Ofrecemos alternativas de inversión para hacer crecer tu dinero con el respaldo de una sólida institución financiera que forma parte de un conglomerado de empresas. Puedes elegir plazos de inversión entre 18 y 24 meses con un monto mínimo de inversión de $5,000.`,
        eventKey: 'q63',
      },
      {
        questionName: '¿Cuál es la tasa?',
        answer: `Es el 9%.`,
        eventKey: 'q64',
      },
      {
        questionName:
          'Y si invierto más de $5,000 ¿Puedo recibir una mejor tasa?',
        answer:
          'El 9% es una tasa altamente competitiva y la mejor del mercado actualmente. Se mantiene la tasa del 9% desde el monto mínimo de inversión.',
        eventKey: 'q65',
      },
      {
        questionName: '¿Cuándo podré retirar mi dinero?',
        answer:
          'Podrá disponer de su dinero en el momento en el que se cumpla la fecha de vencimiento del contrato de inversión',
        eventKey: 'q66',
      },
      {
        questionName: '¿Cuándo vencen los intereses?',
        answer:
          'Los intereses pueden ser pagaderos mensuales, trimestrales, semestrales, anuales o al término del contrato, como sean solicitados.',
        eventKey: 'q67',
      },
      {
        questionName: '¿Sabré exactamente cuántos intereses voy a percibir?',
        answer: `Si, puede hacer uso de la ${linkCalculatorInversion} o bien consultar con nuestros ejecutivos.`,
        eventKey: 'q68',
      },
    ],
  },
  {
    title: 'Remesas',
    icon: `${remesasLogo}`,
    eventKey: 'tab7',
    country: Country.NI,
    questions: [
      {
        questionName: '¿Qué es Zü Remesa?',
        answer:
          'Es nuestro servicio de entrega de remesas y se suma a nuestra lista de productos credex.',
        eventKey: 'q71',
      },
      {
        questionName: '¿Qué es una remesa?',
        answer:
          'Una remesa es un envío de dinero realizado por personas, generalmente familiares, que residen en un país a personas que residen en otro.',
        eventKey: 'q72',
      },
      {
        questionName: '¿Por qué usar Zü Remesa?',
        answer:
          'Entendemos que la elección de un servicio de remesas es una decisión importante. Es por eso que ofrecemos una solución que combina agilidad, conveniencia y seguridad, donde colaboramos con remesadoras confiables, como RIA y Remitly, para garantizar la seguridad de tus transacciones. Lo mejor de todo es que podrás adquirir productos y servicios de SINSA y parte de nuestros comercios afiliados. Como credex estamos comprometidos a brindar a nuestros clientes una experiencia que conecte con cada uno de nuestros productos.',
        eventKey: 'q73',
      },
      {
        questionName:
          '¿Qué remesadoras se encuentran disponibles en las tiendas SINSA?',
        answer:
          'Actualmente, colaboramos con RIA y Remitly para proporcionarte opciones de remesas confiables.',
        eventKey: 'q74',
      },
      {
        questionName: '¿Existe un cobro adicional por recibir mi remesa?',
        answer: 'No existe ningún cobro adicional por parte del beneficiario.',
        eventKey: 'q75',
      },
      {
        questionName: '¿En cuánto tiempo estará disponible mi remesa?',
        answer:
          'Una vez que el ordenante realice el envío, tu remesa estará disponible en tan solo segundos.',
        eventKey: 'q76',
      },
      {
        questionName: '¿Dónde puedo retirar mis remesas?',
        answer:
          'Puedes retirar tus remesas en las tiendas SINSA ubicadas en Carretera Masaya, Home Center y Radial, y próximamente en nuestras tiendas a nivel nacional. Una vez en la tienda, consulta con uno de nuestros asesores, quienes te guiarán a la caja correspondiente.',
        eventKey: 'q77',
      },
      {
        questionName: '¿Cuál es el monto máximo de retiro de remesas?',
        answer:
          'No hay un monto mínimo, solo un máximo mensual por ordenante o beneficiario, esto es debido a disposiciones regulatorias.',
        eventKey: 'q78',
      },
      {
        questionName:
          '¿FID cuenta con regulaciones o permisos para pagar remesas?',
        answer:
          'Sí, FID está debidamente regulado y posee la licencia otorgada por el Banco Central de Nicaragua (BCN) para ofrecer servicios de remesas. Además, cumplimos con disposiciones de seguridad y protección de la información, lo que garantiza la excelencia y confianza en el servicio que ofrecemos a cada uno de nuestros valiosos clientes.',
        eventKey: 'q79',
      },
      {
        questionName: '¿Qué se necesita para retirar tu remesa?',
        answer:
          'Para retirarla, proporciona tu código de la remesa, número de identificación, el nombre de la persona que envió la remesa y tu nombre completo. En tu primera visita, es posible que te solicitemos información adicional, como tu número de teléfono y dirección.',
        eventKey: 'q711',
      },
      {
        questionName: '¿Qué debo hacer cuando retire mi remesa?',
        answer:
          'Te recomendamos contar el efectivo y verificar que la cantidad sea la correcta. Además, firma el recibo proporcionado para confirmar el retiro.',
        eventKey: 'q712',
      },
      {
        questionName: '¿Quién puede realizar el retiro de la remesa?',
        answer:
          'Solo el beneficiario de la remesa está debidamente autorizado para retirar el efectivo.',
        eventKey: 'q713',
      },
      {
        questionName: '¿Qué pasa si no tengo el número de remesa?',
        answer:
          'Lamentamos la situación, sin embargo, para mantener el servicio confiable para los Clientes, Remitentes y Beneficiarios, es indispensable el código de referencia el cual puede solicitar nuevamente a su familiar. La remesa estará disponible siempre y cuando no sea cancelada por el Remitente.',
        eventKey: 'q714',
      },
      {
        questionName: '¿Por qué recibí menos dinero?',
        answer:
          'La diferencia en el monto recibido podría deberse a los costos de envío y las tarifas de servicio de la empresa seleccionada por el remitente. Siempre es recomendable confirmar con el remitente si el monto comunicado es el neto por recibir después de descontar estos cargos. Es importante destacar que Zü Remesa no realiza deducciones adicionales en el pago de la remesa, por lo que el monto que el remitente envía es el monto que deberías recibir, sujeto a los cargos correspondientes por la remesadora.',
        eventKey: 'q715',
      },
      {
        questionName:
          '¿Qué sucede si mi nombre no coincide con el de la remesa?',
        answer:
          'En caso de que los nombres no coincidan, te recomendamos ponerte en contacto con la persona que envió el dinero. Ellos podrán comunicarse con la remesadora y corregir la información para garantizar la entrega del efectivo.',
        eventKey: 'q716',
      },
      {
        questionName:
          '¿Cuáles son los horarios de atención para el retiro de remesas?',
        answer: `Nuestros horarios de atención para el retiro de remesas son amplios y flexibles, de 8 am a 5 pm en la tienda SINSA que visites. ¡Estamos disponibles de lunes a domingo! Si tienes alguna pregunta o necesitas nuestra asistencia, no dudes en contactar a nuestro servicio al cliente a través del número ${liknPhone}.`,
        eventKey: 'q717',
      },
    ],
  },
  {
    title: 'Apertura',
    icon: `${startImg}`,
    eventKey: 'tab1',
    country: Country.GT,
    questions: [
      {
        questionName:
          '¿En cuántas cuotas puedo llevar artículos con una línea de crédito?',
        answer: `Con ${site}, puede llevarlo en cuotas que van desde 3 hasta 48 meses`,
        eventKey: 'q11',
      },
      {
        questionName: '¿Puedo dar enganche para que sea mas baja la cuota?',
        answer: `Perfectamente puede dar enganche, una vez aprobada ${site} línea de acuerdo al monto de esta y según su elección, se calcula una cuota que le favorezca.`,
        eventKey: 'q12',
      },
      {
        questionName: '¿Qué cargos se cobran en la cuota?',
        answer:
          'Se cobran el saldo de capital, el interés corriente y cuota de comisión (Comisión aplica solamente para desembolsos en efectivo)',
        eventKey: 'q13',
      },
      {
        questionName: '¿Y si yo cancelo antes qué pasa?',
        answer: `Si cancela antes no hay cobro por cancelación anticipada, el interés es sobre saldo a la fecha y ${site} línea queda con saldo disponible para cualquier otra compra que desee realizar.`,
        eventKey: 'q14',
      },
    ],
  },
  {
    title: 'Requisitos',
    icon: `${requirementimg}`,
    eventKey: 'tab2',
    country: Country.GT,
    questions: [
      {
        questionName: '¿Cuáles son los requisitos básicos?',
        answer:
          'Ingresos mensuales mayores de 2500 Quetzales. Edad entre 18 y 70 años. 6 meses de trabajar en la misma empresa o, para que quien tenga negocio propio, al menos 12 meses de actividad.',
        eventKey: 'q21',
      },
      {
        questionName: '¿Qúe documentos se solicitan?',
        answer:
          'Inicialmente el documento personal de identificación (DPI) así como del recibo de energía electrica del lugar de vivienda, posterior de acuerdo al perfil del cliente podrían solicitarse documentos adicionales, ejemplo: cartas salariales o estados de cuenta.',
        eventKey: 'q22',
      },
      {
        questionName: 'Si trabajo de forma independiente, ¿puedo aplicar?',
        answer:
          'Perfectamente puede aplicar, luego de acuerdo a la evaluación realizada se le solicitarán documentos o comprobantes de ingreso.',
        eventKey: 'q23',
      },
    ],
  },
  {
    title: `${site} Personal`,
    icon: `${credexImg}`,
    eventKey: 'tab3',
    country: Country.GT,
    questions: [
      {
        questionName: '¿De qué forma recibo mi dinero?',
        answer:
          'Por medio de transferencia electrónica a una cuenta bancaria personal activa.',
        eventKey: 'q31',
      },
      {
        questionName: '¿Tiene que ser en Quetzales la transferencia?',
        answer: 'Si solamente en moneda Quetzal.',
        eventKey: 'q32',
      },
      {
        questionName: '¿En cuánto tiempo recibo mi dinero?',
        answer:
          'En un máximo de 2 días hábiles después de verificado el proceso estará el dinero en su cuenta.',
        eventKey: 'q33',
      },
      {
        questionName:
          '¿Puedo pedir una transferencia a nombre de otra persona?',
        answer:
          'Por el momento el beneficiario debe ser el titular de la línea de crédito.',
        eventKey: 'q34',
      },
      {
        questionName: '¿Qué pasa si me equivoque de cuenta bancaria?',
        answer:
          'Existen puntos de control de seguridad, al momento de la entrevista se le envía un SMS esperando su confirmación y nuestro personal hace constar que la cuenta corresponde.',
        eventKey: 'q35',
      },
      {
        questionName: '¿Puedo pagar antes?',
        answer:
          'Sin ningún problema y además no se generará cobro por pagos anticipados.',
        eventKey: 'q36',
      },
      {
        questionName: '¿Se cobra comisión por desembolso?',
        answer:
          'Sí, es del 5% sobre el monto financiado, se divide entre las cuotas y no genera algún tipo de interés.',
        eventKey: 'q37',
      },
      {
        questionName: '¿Puedo desembolsar todo mi límite de crédito?',
        answer:
          'Depende, esto varía según la cuota máxima, el nivel de endeudamiento y otros factores que se consideran además de las condiciones financieras requeridas por el cliente.',
        eventKey: 'q38',
      },
      {
        questionName: '¿Las tasas de interés cuáles son?',
        answer:
          'Usted podrá encontrar las tasas de interés en la opción de simula tu crédito, la cual irá acorde al producto o plan financiero seleccionado.',
        eventKey: 'q39',
      },
    ],
  },
  {
    title: 'Reclamos',
    icon: `${contactUs}`,
    eventKey: 'tab4',
    country: Country.GT,
    questions: [
      {
        questionName: '¿Dónde puedo acudir para realizar un reclamo?',
        answer: `Puede llamar a nuestra línea de atención al cliente ${phoneCustomerService} o dirigirse a nuestras oficinas centrales. Los asesores podrán dar atención a su reclamo.`,
        eventKey: 'q41',
      },
      {
        questionName:
          '¿Cuánto es el tiempo de respuesta luego de realizar el reclamo?',
        answer: `${site}, deberá dar respuesta a su reclamo en 7 días hábiles, desde la fecha de la recepción del mismo. Si la complejidad del caso lo amerita podrá disponer de un plazo adicional de 5 días adicionales, el cual le será notificado.`,
        eventKey: 'q42',
      },
      {
        questionName: '¿Cuál es el procedimiento para realizar un reclamo?',
        answer: `El cliente debe presentar su reclamo por los medios disponibles de ${site}, donde deberá exponer el motivo de su reclamo, así como la información o soportes que sea necesaria para la atención del mismo. Para mas informacion puede ver proceso de atención a reclamos.`,
        eventKey: 'q43',
      },
      {
        questionName:
          '¿Dónde puedo obtener información del estado de mi reclamo?',
        answer: `El cliente podrá obtener información del estado o avances de su reclamo llamando a la línea de atención al cliente ${phoneCustomerService}.`,
        eventKey: 'q44',
      },
    ],
  },
];
