import React, { ReactElement } from 'react';
import { Modal } from 'react-bootstrap';

import './index.css';

export interface ModalChildProps {
  onHide(): void;
}

export interface ModalProps {
  render(props: ModalChildProps): ReactElement;
  title: string;
  show: boolean;
  onHide(): void;
  className?: string;
}

export const MainModal = (props: ModalProps): ReactElement => {
  return (
    <Modal
      size="lg"
      centered
      backdrop="static"
      keyboard={false}
      show={props.show}
      onHide={props.onHide}
      className={`bg-modal ${props?.className}`}
    >
      <Modal.Header closeButton>
        <Modal.Title className="title-bryant">{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ overflow: 'auto' }}>
        {props.render({ onHide: props.onHide })}
      </Modal.Body>
    </Modal>
  );
};

export default MainModal;
