import { sha256 } from 'js-sha256';

import { HttpRepository } from 'infraestructure/repositories/BaseHttpRepository';

export class AuthRepository extends HttpRepository {
  endpoint = 'auth/secure';
  scope = 'customer';
  // service = 'customer';

  private tokenKey = '__auth__';

  protected getService(): string {
    return process.env.REACT_APP_AUTH_SERVICE || '';
  }

  login = async (username: string, passwod: string): Promise<string | null> => {
    const hasher = sha256.create();
    const hashedPassword = hasher.update(passwod).hex().toString();

    const body = {
      userName: username,
      password: hashedPassword,
      scope: this.scope,
    };
    const response = await this.client.post(
      `${this.endpoint}/authenticate`,
      body
    );
    if (response) {
      const { access_token, user_data } = response.data as any;
      localStorage.setItem('user_data', JSON.stringify(user_data));
      return access_token;
    }
    return null;
  };

  resetpassword = async (data: any): Promise<any> => {
    return await this.client.post(`${this.endpoint}/resetpassword`, data);
  };

  async validatetoken(
    uuid: string | null,
    token: string | null,
    country: string | null
  ): Promise<any> {
    return await this.client.get(`${this.endpoint}/validatetoken`, {
      uid: uuid,
      hash: token,
      country: country,
    });
  }

  changepassword = async (data: any): Promise<any> => {
    return await this.client.post(`${this.endpoint}/changepassword`, data);
  };

  storeToken = (token: string): void => {
    localStorage.setItem(this.tokenKey, token);
  };

  loadToken = (): string | null => {
    return localStorage.getItem(this.tokenKey);
  };

  removeToken = (): void => {
    localStorage.removeItem(this.tokenKey);
  };

  getUser = (): any => {
    return JSON.parse(localStorage.getItem('user_data')!);
  };
}
