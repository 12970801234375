import React, { ReactElement } from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';

export interface loadingProps {
  textLoading: string;
}

const LoadingComponent = ({ textLoading }: loadingProps): ReactElement => {
  return (
    <Container className="spinner-show">
      <Row>
        <Col xs={12} className="text-center">
          <Spinner animation="grow" variant="danger">
            <span className="sr-only">{textLoading}</span>
          </Spinner>
        </Col>
        <Col xs={12}>
          <span className="h5 title-bryant text-secondary text-center">
            {textLoading}
          </span>
        </Col>
      </Row>
    </Container>
  );
};
export default LoadingComponent;
