import axios, { AxiosInstance } from 'axios';
import { HttpClient, HttpResponse } from 'core/base/HttpClient';
import { Authentication, ErrorHandler } from 'infraestructure/middlewares';

export class AxiosHttpClient implements HttpClient {
  private static _axios: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
  });
  private static _instance?: AxiosHttpClient;
  protected client: AxiosInstance;

  private constructor() {
    this.client = AxiosHttpClient._axios;
    this.configureInterceptors();
  }

  private configureInterceptors() {
    this.client.interceptors.request.use(Authentication);
    this.client.interceptors.response.use(undefined, ErrorHandler);
  }

  static instance(): HttpClient {
    if (!this._instance) {
      this._instance = new AxiosHttpClient();
    }
    return this._instance;
  }

  // static createInstance(service: string): HttpClient {
  //   return new AxiosHttpClient(service);
  // }

  get = async (
    url: string,
    params?: Record<string, string | number | boolean>,
    data?: any
  ): Promise<HttpResponse> => {
    return await this.client.get(url, { params, data });
  };

  post = async (
    url: string,
    data: Record<string, string | number | boolean>
  ): Promise<HttpResponse> => await this.client.post(url, data);
  put = async (
    url: string,
    data: Record<string, string | number | boolean>
  ): Promise<HttpResponse> => await this.client.put(url, data);
  delete = async (url: string): Promise<HttpResponse> =>
    await this.client.delete(url);
}

export default AxiosHttpClient;
