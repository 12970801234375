import { Country } from 'domain/constances/country';
import { NavBarMenuItem } from 'presentation/components';
import Routes from 'presentation/routes';

export const MenuItemList: NavBarMenuItem[] = [
  { title: 'Inicio', url: Routes.root, country: Country.NI },
  { title: 'Inicio', url: Routes.root, country: Country.GT },
  { title: 'Nosotros', url: Routes.aboutus, country: Country.NI },
  { title: 'Nosotros', url: Routes.aboutus, country: Country.GT },
  { title: 'Comercios Afiliados', url: Routes.affiliated, country: Country.NI },
  { title: 'Comercios Afiliados', url: Routes.affiliated, country: Country.GT },
  { title: 'Puntos de Pago', url: Routes.paymentPoints, country: Country.NI },
  { title: 'Puntos de Pago', url: Routes.paymentPoints, country: Country.GT },
  {
    title: 'Servicio al Cliente',
    url: Routes.customerService,
    country: Country.NI,
  },
  {
    title: 'Servicio al Cliente',
    url: Routes.customerService,
    country: Country.GT,
  },
  { title: 'Factoring', url: Routes.factoring, country: Country.NI },
  //{ title: 'Promociones', url: Routes.promotionHistory, country: Country.NI },
  { title: 'Inversiones', url: Routes.inversiones, country: Country.NI },
  {
    title: 'Efectivo de inmediato',
    url: Routes.instantCash,
    country: Country.GT,
  },
];

export const AuthenticatedUserMenuItems: NavBarMenuItem[] = [
  { title: 'Mi cuenta', url: Routes.dashboard, country: Country.NI },
  { title: 'Mi cuenta', url: Routes.dashboard, country: Country.GT },
];
