import { JsonRepository } from '../JsonRepository';

import { NewsData } from '../../../data/news-data';
import News from 'domain/models/News';

export class NewsRepository extends JsonRepository {
  protected data: News[] = NewsData;

  getRelated = async (country: string) => {
    return await this.list({ country });
  };

  search = async (country: string, text: string) => {
    return new Promise((resolv, reject) => {
      const news = this.data.filter((item: News) => {
        return (
          item.country === country &&
          item.title.toLocaleLowerCase().includes(text.toLocaleLowerCase())
        );
      });
      resolv({ data: news });
    });
  };
}

export default NewsRepository;
